import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBuilderComponent, BuilderDataAbstract } from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';

interface BaseVSData {
  unit: string;
  value: number;
  _modified: number;
}

interface VitalSignsFormData extends BuilderDataAbstract {
  pulse: BaseVSData;
  respiratory_rate: BaseVSData;
  blood_pressure: {
    unit: string;
    value: string;
    _modified: number;
  };
  height: BaseVSData;
  blood_glucose: BaseVSData;
  oxygen_saturation: BaseVSData;
  temperature: {
    unit: string;
    value: number;
    region: 'ear' | 'rectal' | 'axilla';
    _modified: number;
  };
  weight: BaseVSData;
}

@Component({
  selector: 'app-vital-signs',
  templateUrl: './vital-signs.component.html',
  styleUrls: ['./vital-signs.component.scss']
})
export class VitalSignsComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      pulse: this.fb.group({
        unit: ['bpm'],
        value: [null]
      }),
      respiratory_rate: this.fb.group({
        unit: ['RR/min'],
        value: [null]
      }),
      blood_pressure: this.fb.group({
        unit: ['mmHG'],
        value: [null],
        validators: [Validators.pattern(/\d+\/\d+/)]
      }),
      height: this.fb.group({
        unit: ['cm'],
        value: [null]
      }),
      blood_glucose: this.fb.group({
        unit: ['mmol/l'],
        value: [null]
      }),
      oxygen_saturation: this.fb.group({
        unit: ['%'],
        value: [null]
      }),
      temperature: this.fb.group({
        unit: ['°C'],
        region: [],
        value: [null]
      }),
      weight: this.fb.group({
        unit: ['kg'],
        value: [null]
      }),
    });
  }

  public get bmi() {
    if (!!(this.formData.get('weight') as FormGroup).get('value')?.value && !!(this.formData.get('height') as FormGroup).get('value')?.value) {
      return (parseFloat((this.formData.get('weight') as FormGroup).get('value')?.value) /
        Math.pow(parseFloat(String(((this.formData.get('height') as FormGroup).get('value')?.value) / 100)), 2)).toFixed(1);
    }
    return 'No Data';
  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: VitalSignsFormData): HistoryData[] {
    if (!data) {
      return [];
    }
    const previewData = {
      ...data,
      bmi: data.weight && data.height && data.weight.value && data.height.value
        ? (data.weight.value / Math.pow(data.height.value / 100, 2)).toFixed(1)
        : 'No Data'
    };
    return [{
      type: 'builder',
      builderTitle: 'VITALS:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, previewData]
    }];
  }

  asString(data: VitalSignsFormData): string {
    if (!data) return '';

    const bmi = data.weight && data.height && data.weight.value && data.height.value
      ? (data.weight.value / Math.pow(data.height.value / 100, 2)).toFixed(1)
      : 'No Data';
    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: VITALS`,
      ...(data.pulse ? [`Pulse: ${data.pulse.value + data.pulse.unit}`] : []),
      ...(data.respiratory_rate ? [`RR: ${data.respiratory_rate.value + data.respiratory_rate.unit}`] : []),
      ...(data.oxygen_saturation ? [`SpO2: ${data.oxygen_saturation.value + data.oxygen_saturation.unit}`] : []),
      ...(data.blood_pressure ? [`BP: ${data.blood_pressure.value + data.blood_pressure.unit}`] : []),
      ...(data.blood_glucose ? [`Blood Glucose: ${data.blood_glucose.value + data.blood_glucose.unit}`] : []),
      ...(data.temperature ? [`Body Temp: ${(data.temperature.value + data.temperature.unit + (data.temperature.region ? ' - ' + data.temperature.region : ''))}`] : []),
      ...(data.height ? [`Height: ${data.height.value + data.height.unit}`] : []),
      ...(data.weight ? [`Weight: ${data.weight.value + data.weight.unit}`] : []),
      `Calculated BMI: ${bmi}`,
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as VitalSignsFormData;
    Object.entries(data).forEach(([control, formData]) => {
      if (!formData.value) {
        delete data[control as keyof VitalSignsFormData];
      }
    });

    data.pulse ? data.pulse['_modified'] = moment().valueOf() : '';
    data.respiratory_rate ? data.respiratory_rate['_modified'] = moment().valueOf() : '';
    data.blood_pressure ? data.blood_pressure['_modified'] = moment().valueOf() : '';
    data.height ? data.height['_modified'] = moment().valueOf() : '';
    data.blood_glucose ? data.blood_glucose['_modified'] = moment().valueOf() : '';
    data.oxygen_saturation ? data.oxygen_saturation['_modified'] = moment().valueOf() : '';
    data.temperature ? data.temperature['_modified'] = moment().valueOf() : '';
    data.weight ? data.weight['_modified'] = moment().valueOf() : '';

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      data.pulse ? builderData['pulse'] = data.pulse : '';
      data.respiratory_rate ? builderData['respiratory_rate'] = data.respiratory_rate : '';
      data.blood_pressure ? builderData['blood_pressure'] = data.blood_pressure : '';
      data.height ? builderData['height'] = data.height : '';
      data.blood_glucose ? builderData['blood_glucose'] = data.blood_glucose : '';
      data.oxygen_saturation ? builderData['oxygen_saturation'] = data.oxygen_saturation : '';
      data.temperature ? builderData['temperature'] = data.temperature : '';
      data.weight ? builderData['weight'] = data.weight : '';
    }
    return builderData;
  }

}
