<div class="container-fluid" [formGroup]="formData" jargonViewModeForm="write">
  <!-- <div class="row">
    <app-history-view [title]="'Treatment'" [data]="noteData" [commentQty]="data.comments?.length"
                      [alias]="config.alias"
                      (reviewAction)="handleReviewAction($event)"></app-history-view>
  </div> -->
  <div class="row my-4 plan-row">
    <label class="form-label">Plan</label>
    <div class="col-12">
      <textarea class="form-control" placeholder="Start typing here..." formControlName="plan"
                jargonLoadingState="builder-control"></textarea>
    </div>
  </div>
  <div class="row medication-row">
    <label class="form-label">Medications</label>

    <div class="col-12 mb-4">
      <ng-container formArrayName="medications">
        <div class="row g-2" *ngFor="let row of getRow('medications').controls; let i = index"
             [formGroupName]="i">
          <div class="col-2">
            <medprax-code-typeahead type="medicine" formControlName="nappi" jargonLoadingState="builder-control"
                                    placeholder="Name (start typing to search NAPPI)">
            </medprax-code-typeahead>
          </div>
          <div class="col-2">
            <select type="text" class="form-control" formControlName="type" jargonLoadingState="builder-control">
              <option hidden disabled [value]="null">Type</option>
              <option value="acute">Acute</option>
              <option value="chronic">Chronic</option>
            </select>
          </div>
          <div class="col-2">
            <div class="input-group">
              <input type="number" class="form-control" placeholder="Dosage" formControlName="quantity"
                     jargonLoadingState="builder-control">
              <select type="text" class="form-control" formControlName="unit" jargonLoadingState="builder-control">
                <option hidden disabled [value]="null">Units</option>
                <option value="capsule">capsule</option>
                <option value="drops">drops</option>
                <option value="g">g</option>
                <option value="mg">mg</option>
                <option value="ml">ml</option>
                <option value="sachet">sachet</option>
                <option value="spray">spray</option>
                <option value="tablet">tablet</option>
                <option value="ug">ug</option>
                <option value="units">units</option>
              </select>
            </div>
          </div>
          <div class="col-1">
            <select type="text" class="form-control" formControlName="frequency" jargonLoadingState="builder-control">
              <option hidden disabled [value]="null">Frequency</option>
              <option value="12 hourly">12 hourly</option>
              <option value="4 hourly">4 hourly</option>
              <option value="5 times/day">5 times/day</option>
              <option value="6 hourly">6 hourly</option>
              <option value="8 hourly">8 hourly</option>
              <option value="bd">bd</option>
              <option value="daily">daily</option>
              <option value="mane">mane</option>
              <option value="nocte">nocte</option>
              <option value="prn">prn</option>
              <option value="qid">qid</option>
              <option value="stat">stat</option>
              <option value="tds">tds</option>
            </select>
          </div>
          <div class="col-2">
            <input type="text" class="form-control" placeholder="Time Period" formControlName="period"
                   jargonLoadingState="builder-control">
          </div>
          <div class="col-1">
            <select type="text" class="form-control" formControlName="route" jargonLoadingState="builder-control">
              <option hidden disabled [value]="null">Route of Delivery</option>
              <option value="Buccally">Buccally</option>
              <option value="IM">IM</option>
              <option value="In Affected Ear">In Affected Ear</option>
              <option value="IV">IV</option>
              <option value="Locally">Locally</option>
              <option value="Nasally">Nasally</option>
              <option value="Nasally (In Each Nostril)">Nasally (In Each Nostril)</option>
              <option value="Ocular (In Affected Eye)">Ocular (In Affected Eye)</option>
              <option value="Ocular (In Both Eyes)">Ocular (In Both Eyes)</option>
              <option value="P.O">P.O</option>
              <option value="P.R. S.C.">P.R. S.C.</option>
              <option value="P.V.">P.V.</option>
              <option value="PR">PR</option>
              <option value="SC">SC</option>
              <option value="SL">SL</option>
              <option value="Topically">Topically</option>
            </select>
          </div>
          <div class="col-2">
            <div class="input-group mb-3">
              <input class="form-control" placeholder="Date" formControlName="date_of_script" ngbDatepicker
                     jargonLoadingState="builder-control"
                     #tsDate="ngbDatepicker" (click)="tsDate.toggle()">
              <ng-container *jargonViewMode="'write'">
                <button class="btn btn-outline-secondary btn-sm"
                        type="button"
                        jargonLoadingState="builder-control"
                        (click)="removeRow('medications', i)">
                  <mat-icon>cancel</mat-icon>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *jargonViewMode="'write'">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-outline-primary" (click)="addRow('medications')"
                    jargonLoadingState="builder-control">Add +</button>
          </div>
        </div>
      </ng-container>
    </div>

  </div>

  <div class="row procedure-row">
    <label class="form-label">Procedure(s)</label>

    <div class="col-12 mb-4">
      <ng-container formArrayName="procedures">
        <div class="row g-2" *ngFor="let row of getRow('procedures').controls; let i = index"
             [formGroupName]="i">
          <div class="col-4">
            <medprax-code-typeahead type="tariff" formControlName="code" jargonLoadingState="builder-control"
                                    placeholder="Start typing to search procedure codes..">
            </medprax-code-typeahead>
          </div>
          <div class="col-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Notes" formControlName="description"
                     jargonLoadingState="builder-control">
              <ng-container *jargonViewMode="'write'">
                <button class="btn btn-outline-secondary btn-sm"
                        type="button"
                        jargonLoadingState="builder-control"
                        (click)="removeRow('procedures', i)">
                  <mat-icon>cancel</mat-icon>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *jargonViewMode="'write'">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-outline-primary" (click)="addRow('procedures')"
                    jargonLoadingState="builder-control">Add +</button>
          </div>
        </div>
      </ng-container>
    </div>

  </div>
  <ng-container *jargonViewMode="'write'">
    <div class="row script-row">
      <div class="col-12">
        <label class="form-label">Scripting</label>
      </div>
      <div class="col-12">
        <button *ngIf="!activeEscriptingProvider"
                class="btn btn-primary me-2"
                (click)="uploadAttachment()"
                jargonLoadingState="builder-control">
          Create Script
        </button>
        <button *ngIf="activeEscriptingProvider"
                class="btn btn-primary me-2"
                (click)="createScript()"
                jargonLoadingState="builder-control">
          Launch e-Scripting
        </button>
        <!-- <button class="btn btn-outline-primary" (click)="rxHistory()">Rx History</button> -->
      </div>
    </div>
  </ng-container>
</div>


<ng-template #preview let-data="data">
  <p>
    <ng-container *ngIf="data.plan">
      <strong>Treatment Plan: </strong>
      <p class="m-0">{{data.plan}}</p><br />
    </ng-container>
    <ng-container *ngIf="data.medications?.length">
      <strong>Medications: </strong>
      <span *ngFor="let c of data.medications; let i = index">
        ({{i + 1}}) [{{c.nappi.Code}}] {{c.nappi.Name || c.nappi.FullDescription || ''}}: {{c.type}} -
        {{c.quantity}}{{c.unit}} {{c.route}}
        {{c.frequency}} {{c.period}}
        [{{c.date_of_script}}]
      </span><br />
    </ng-container>
    <ng-container *ngIf="data.procedures?.length">
      <strong>Procedures: </strong>
      <span *ngFor="let v of data.procedures; let i = index">
        ({{i + 1}}) {{ v.code?.Code || v.code || '' }}{{ v.code?.Description ? ' ' + v.code?.Description : '' }}{{
        v.description ? ' - ' + v.description : '' }}
      </span><br />
    </ng-container>
  </p>
</ng-template>