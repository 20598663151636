import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { LoadingStateService } from '../../services/common/loading-state.service';
import { MedpraxNappiCodeQueryItem } from 'src/app/shared/types/medprax';
import { AttachmentModel } from '../../models/attachment.model';
import { DialogService } from '../../services/common/dialog.service';
import { AttachmentHttpService } from '../../services/http/attachment-http.service';
import { ClinicalNoteService } from '../../services/common/clinical-note.service';
import {
  catchError,
  finalize,
  firstValueFrom,
  map,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { UI_SECTIONS } from 'src/app/modules/ui/config/sections';
import { validateAllFormFields } from '../../validators';
import { UiService } from '../../services/common/ui.service';
import { ActiveUserService } from '../../services/common/active-user.service';
import { AttachmentFormMetaData } from '../attachments-modal/attachments-modal.component';
import { TreatmentMedicationFormData } from 'src/app/modules/sections/components/treatment-section/treatment-section.component';

type Medication = TreatmentMedicationFormData & { _id: string };
interface ScriptPayload {
  type: 'script';
  note: string;
  meta: AttachmentFormMetaData;
  data: {
    medications: Array<Medication>
  };
}

@Component({
  selector: 'app-script-modal',
  templateUrl: './script-modal.component.html',
  styleUrls: ['./script-modal.component.scss'],
})
export class ScriptModalComponent implements OnInit {
  @Input() modalRef!: NgbModalRef;
  @Input() closure!: (file: AttachmentModel) => void;
  @Input() medications!: Array<Medication>;
  public maxDateNow = {
    day: moment().date(),
    month: moment().month() + 1,
    year: moment().year(),
  };
  public scriptForm: FormGroup = new FormGroup({
    date_time: new FormControl({ ...this.maxDateNow }, Validators.required),
    type: new FormControl(null, Validators.required),
    substitutes: new FormControl(null, Validators.required),
    reason: new FormControl('generated'),
  });
  public noteControl: FormControl = new FormControl(null, Validators.required);
  public patientName: string;

  constructor(
    private loadingStateService: LoadingStateService,
    private activeUserService: ActiveUserService,
    private clinicalNoteService: ClinicalNoteService,
    private attachmentHttpService: AttachmentHttpService,
    private dialogService: DialogService,
    private uiService: UiService
  ) {
    this.patientName = `${clinicalNoteService.getPatient().first_name} ${clinicalNoteService.getPatient().last_name}`;
  }

  ngOnInit(): void {
  }

  public removeMedication(medicationId: string): void {
    this.medications = this.medications.filter(
      (medication) => medication._id !== medicationId
    );
  }

  public get substitutes() {
    return this.scriptForm.get('substitutes')?.value;
  }

  public submit() {
    validateAllFormFields(this.scriptForm);
    this.noteControl.markAsTouched();
    if (this.scriptForm.invalid || this.noteControl.invalid) return;

    const formData: ScriptPayload = {
      type: 'script',
      note: this.noteControl.value,
      data: {
        medications: this.medications
      },
      meta: this.scriptForm.value,
    };

    return this.generateAttachment(formData);
  }

  private async generateAttachment(data: ScriptPayload) {
    this.loadingStateService.start('script-modal');
    await firstValueFrom(
      this.attachmentHttpService
        .generate(
          this.clinicalNoteService.patientId!,
          this.clinicalNoteService.guid!,
          data
        )
        .pipe(
          tap((res: AttachmentModel) => {
            this.closure(res);
          }),
          switchMap(() =>
            this.clinicalNoteService.loadSection(UI_SECTIONS.ATTACHMENTS)
          ),
          tap(() => {
            this.dialogService.show({
              variant: 'success',
              title: 'Script Generated Successfully!',
            });
            this.modalRef?.close();
          }),
          finalize(() => {
            this.loadingStateService.end('script-modal');
          }),
          catchError((err) => {
            this.loadingStateService.end('script-modal');
            this.dialogService.show({
              variant: 'danger',
              title: 'Error',
              message: err.message,
            });
            console.error(err);
            return throwError(() => err);
          })
        )
    );
  }
}
