import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { AbstractSectionComponent } from '../../shared/components/abstract-section/abstract-section.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActiveUserService } from '../../../../shared/services/common/active-user.service';
import { ClinicalNoteService } from '../../../../shared/services/common/clinical-note.service';
import { ReviewService } from '../../../../shared/services/common/review.service';
import { AttachmentsModalComponent } from '../../../../shared/components/attachments-modal/attachments-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { MedpraxNappiCodeQueryItem, MedpraxTariffCodeQueryItem } from 'src/app/shared/types/medprax';
import { validateAllFormFields } from 'src/app/shared/validators';
import * as moment from 'moment';
import { AttachmentModel } from 'src/app/shared/models/attachment.model';
import { HistoryData } from 'src/app/shared/components/history-view/history-view.component';
import { ClinicalNoteModel, ProcedureDataModel } from 'src/app/shared/models/clincal-note.model';
import { DialogService } from 'src/app/shared/services/common/dialog.service';
import { firstValueFrom } from 'rxjs';
import { IntegrationModel } from '../../../../shared/models/integration.model';
import { IntegrationTypes } from 'src/app/shared/types/enum/integration';
import { ScriptModalComponent } from 'src/app/shared/components/script-modal/script-modal.component';

export interface TreatmentMedicationFormData {
  nappi: MedpraxNappiCodeQueryItem;
  type: string;
  quantity: number;
  unit: string;
  frequency: string;
  period: string;
  route: string;
  date_of_script: number;
}

interface TreatmentFormData extends Partial<ClinicalNoteModel> {
  _modified: number;
  script: Array<string>;
  plan: string;
  medications: Array<TreatmentMedicationFormData>;
  procedures: Array<{
    code: MedpraxTariffCodeQueryItem;
    description: string
  }>;
}

@Component({
  selector: 'app-treatment-section',
  templateUrl: './treatment-section.component.html',
  styleUrls: ['../../shared/components/abstract-section/abstract-section.component.scss', './treatment-section.component.scss']
})
export class TreatmentSectionComponent extends AbstractSectionComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  public formData: FormGroup;
  public activeEscriptingProvider: IntegrationModel | undefined;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal
  ) {
    super();
    this.formData = this.fb.group({
      script: [[]],
      plan: [null, Validators.maxLength(1500)],
      procedures: this.fb.array([]),
      medications: this.fb.array([])
    });
    this.loadProviders();
  }

  override ngOnInit() {
    super.ngOnInit();
    if (this.data) {
      if (this.data.procedures && this.data.procedures.length) {
        this.data.procedures?.forEach(() => this.addRow('procedures'));
      } else {
        this.addRow('procedures');
      }
      if (this.data.medications && this.data.medications.length) {
        this.data.medications?.forEach(() => this.addRow('medications'));
      } else {
        this.addRow('medications');
      }
      this.formData.patchValue(this.data);
    } else {
      this.addRow('medications');
      this.addRow('procedures');
    }
  }

  override setNoteData() {
    const notes = this.data.notes ? this.data.notes.map(({ author, content, _modified }) => ({
      author: author.name,
      dateTime: moment(_modified).format('YYYY-MM-DD HH:mm:ss Z'),
      content
    })) : [];
    const treatmentNotes: HistoryData[] = [];
    if (this.data) {
      if (this.data.plan) {
        treatmentNotes.push({
          icon: 'article',
          sectionTitle: 'PLAN',
          dateTime: moment(this.data._modified).format('YYYY-MM-DD HH:mm:ss Z'),
          content: this.data.plan,
        });
      }
      if (this.data.medications && this.data.medications.length) {
        treatmentNotes.push({
          icon: 'medication',
          sectionTitle: 'MEDICATIONS',
          dateTime: moment(this.data._modified).format('YYYY-MM-DD HH:mm:ss Z'),
          content: this.data.medications?.map(
            v => `${v.nappi?.Name || v.nappi?.FullDescription || v.nappi || ''} ${v.quantity || ''}${v.unit || ''} ${v.frequency || ''} ${v.period || ''} ${v.route || ''} (${v.type || ''} - ${v.date_of_script || ''})`
          ).join('\n'),
        });
      }
      if (this.data.procedures && this.data.procedures.length) {
        treatmentNotes.push({
          icon: 'science',
          sectionTitle: 'PROCEDURES',
          dateTime: moment(this.data._modified).format('YYYY-MM-DD HH:mm:ss Z'),
          content: this.data.procedures?.map(
            (v: ProcedureDataModel) => `${v.code?.Code || v.code || ''}${v.code?.Description ? ' ' + v.code?.Description : ''}${v.description ? ' - ' + v.description : ''}`
          ).join('\n'),
        });
      }
    }
    this.controls.getSerializedBuilders().then(v => {
      this.noteData = [...notes, ...treatmentNotes, ...v];
    });
  }

  public getRow(form: keyof TreatmentFormData): FormArray {
    return this.formData.get(form) as FormArray;
  }

  public removeRow(form: 'medications' | 'procedures', idx: number) {
    this.getRow(form).removeAt(idx);
  }

  public addRow(form: 'medications' | 'procedures') {
    const row = {
      medications: () => ({
        nappi: [null, Validators.required],
        type: [null, Validators.required],
        quantity: [null, Validators.required],
        unit: [null, Validators.required],
        frequency: [null, Validators.required],
        period: [null, [Validators.required, Validators.maxLength(50)]],
        route: [null, Validators.required],
        date_of_script: [null, Validators.required],
      }),
      procedures: () => ({
        code: [null, Validators.required],
        description: [null, Validators.maxLength(500)]
      })
    }[form];
    const group = this.fb.group(row());
    this.getRow(form).push(group);
  }

  public uploadAttachment() {
    const modalRef = this.modalService.open(ScriptModalComponent, { size: 'xl' });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.closure = this.closure.bind(this);
    modalRef.componentInstance.medications = this.data.medications;
  }

  public rxHistory() {

  }

  public closure(data: AttachmentModel) {
    const existing = this.formData.value.script || [];
    this.formData.patchValue({ script: [...existing, `_id:${data._id}`] });
    this.save();
  }

  public override async validate(): Promise<boolean> {
    let emptyControls: Array<'medications' | 'procedures'> = [];
    Object.entries(this.formData.value as TreatmentFormData).filter(([control]) => ['medications', 'procedures'].includes(control)).forEach(([control, controlValue]) => {
      if (controlValue && controlValue.length === 1) {
        const empty = Object.values(controlValue[0]).reduce((out, value) => {
          if (value !== null && typeof value === 'object') {
            return Object.values(value as Object).reduce((out, value) => out && (value === null || value === ''), true);
          }
          return out && (value === null || value === '' || (Array.isArray(value) && value.length === 0))
        }, true);
        if (empty) {
          (this.formData.get(control) as FormArray).removeAt(0);
          emptyControls.push(control as 'medications' | 'procedures');
        }
      }
    });

    validateAllFormFields(this.formData);
    const res = this.formData.valid;
    if (emptyControls.length > 0) {
      emptyControls.forEach(c => this.addRow(c));
    }
    return res;
  }

  public override async save(): Promise<boolean | null> {
    let emptyControls: Array<'medications' | 'procedures'> = [];
    Object.entries(this.formData.value as TreatmentFormData).filter(([control]) => ['medications', 'procedures'].includes(control)).forEach(([control, controlValue]) => {
      if (controlValue && controlValue.length === 1) {
        const empty = Object.values(controlValue[0]).reduce((out, value) => {
          if (value !== null && typeof value === 'object') {
            return Object.values(value as Object).reduce((out, value) => out && (value === null || value === ''), true);
          }
          return out && (value === null || value === '' || (Array.isArray(value) && value.length === 0))
        }, true);
        if (empty) {
          (this.formData.get(control) as FormArray).removeAt(0);
          emptyControls.push(control as 'medications' | 'procedures');
        }
      }
    });

    const data = this.formData.value as TreatmentFormData;

    this.data.plan = data.plan;
    this.data.script = data.script;
    this.data.procedures = data.procedures.map(d => ({ ...d, _modified: moment().valueOf() }));
    this.data.medications = data.medications.map(d => ({ ...d, _modified: moment().valueOf() }));

    const res = super.save();

    if (emptyControls.length > 0) {
      emptyControls.forEach(c => this.addRow(c));
    }

    return res;
  }

  override serialize(data: TreatmentFormData): HistoryData[] {
    if (!data || !(data.plan || data.medications?.length || data.procedures?.length || data.script)) {
      return [...super.serialize(data)];
    }

    return [...super.serialize(data), {
      type: 'section',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, {
        plan: data.plan,
        medications: data.medications.map(v => ({
          ...v,
          date_of_script: moment(v.date_of_script).format('YYYY-MM-DD')
        })),
        procedures: data.procedures
      }]
    }];
  }

  override asString(data: Partial<TreatmentFormData>): string {
    const dataStrings = [];
    if (data.plan) {
      dataStrings.push(`Treatment Plan: ${data.plan}`);
    }
    if (data.medications && data.medications.length > 0) {
      dataStrings.push('Medications:');
      data.medications.forEach(
        (c, i) => dataStrings.push(`(${i + 1}) [${c.nappi.Code}] ${c.nappi.Name || c.nappi.FullDescription}: ${c.type} - ${c.quantity}${c.unit} ${c.route} ${c.frequency} ${c.period} [${moment(c.date_of_script).format('YYYY-MM-DD')}]`)
      );
    }
    if (data.procedures && data.procedures.length > 0) {
      dataStrings.push('Procedures:');
      data.procedures.forEach(
        (c, i) => dataStrings.push(`(${i + 1}) ${c.code?.Code || c.code || ''}${c.code?.Description ? ' ' + c.code?.Description : ''}${c.description ? ' - ' + c.description : ''}`)
      );
    }

    if (dataStrings.length > 0) {
      dataStrings.unshift(`[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author?.name ? `; ${data.author?.name}` : ''}]:`)
    }

    const notes = (data.notes || []).sort((a, b) => a._modified && b._modified ? (a._modified > b._modified ? 1 : -1) : -1)
    return [
      notes.filter(v => !v._modified || v._modified < data._modified!).map(
        (note) => `[${moment(note._modified).format('YYYY-MM-DD HH:mm:ss Z')}; ${note.author.name}] ${note.content}`
      ),
      ...dataStrings,
      notes.filter(v => v._modified && v._modified > data._modified!).map(
        (note) => `[${moment(note._modified).format('YYYY-MM-DD HH:mm:ss Z')}; ${note.author.name}] ${note.content}`
      )
    ].filter(v => !!v && v !== '').join('\n');
  }

  public async createScript() {
    await firstValueFrom(
      this.uiService.request('providers', 'submit', {
        provider: this.activeEscriptingProvider
      })
    );
  }

  private async loadProviders() {
    const msg = await firstValueFrom(
      this.uiService.request('providers', 'provider-list', {})
    );
    if (msg && msg.providers && msg.providers.length) {
      // Will find and use first scripting provider
      this.activeEscriptingProvider = (msg.providers as IntegrationModel[]).find(
        integration => [IntegrationTypes.EMGUIDANCE_SCRIPT as string].includes(integration.type.alias)
      );
    }
  }
}
