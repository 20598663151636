import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AttachmentModel } from '../../models/attachment.model';
import { MessageResponse } from '../../types/messageResponse';
import { CallsAPI } from '../abstract/http/calls-api';
import { HttpOptions } from '../abstract/http/http-api.service';


@Injectable({
    providedIn: 'root'
})
export class AttachmentHttpService extends CallsAPI<AttachmentModel, AttachmentModel[]> {
    protected apiBasePath: string = environment.api;
    protected resourcePath: string = '/patient/{:patientId}/case/{:guid}/attachments';

    /**
       * @param {*} id
       * @param {HttpOptions} options
       * @returns
       * @memberof HttpApiService
       */
    public download(patientId: number, guid: string, id: string): Observable<any> {
        return this.http.get(this.downloadPath(patientId, guid, id), { responseType: 'blob' });
    }

    /**
       * @param {*} id
       * @param {HttpOptions} options
       * @returns
       * @memberof HttpApiService
       */
    public downloadPath(patientId: number, guid: string, id: string): string {
        return this.buildAction('/{:id}', { patientId, guid, id });
    }

    /**
       * @param {*} id
       * @param {HttpOptions} options
       * @returns
       * @memberof HttpApiService
       */
    public delete(patientId: number, guid: string, id: string, options?: HttpOptions): Observable<MessageResponse> {
        return this.http.delete<MessageResponse>(this.buildAction('/{:id}', { patientId, guid, id }), this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            }),
        );
    }

    /**
       * @param {*} body
       * @param {HttpOptions} options
       * @returns
       * @memberof HttpApiService
       */
    public upload(patientId: number, guid: string, body: any, options?: HttpOptions): Observable<AttachmentModel[]> {
        return this.http.post<AttachmentModel[]>(this.buildAction('/', { patientId, guid }), body, this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }

    /**
   * @param {*} body
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
    public generate(patientId: number, guid: string, body: any, options?: HttpOptions): Observable<AttachmentModel> {
        return this.http.post<AttachmentModel>(this.buildAction('/generate', { patientId, guid }), body, this.buildOptions(options)).pipe(
            catchError(err => {
                return throwError(() => this.handleValidationErrors(err));
            })
        );
    }
}