<div class="container-fluid">
  <div class="modal-header">
    <h4 class="modal-title attachments-modal-title">Create Script</h4>
  </div>
  <div class="modal-body" [formGroup]="scriptForm">
    <div class="row mb-3">
      <div class="form-group col-6">
        <label>Patient Name</label>
        <input
               jargonLoadingState="script-modal"
               type="text"
               class="form-control"
               value="{{ patientName }}"
               disabled />
      </div>

      <div class="form-group col-6">
        <label>Date of Script</label>
        <input
               jargonLoadingState="script-modal"
               class="form-control"
               formControlName="date_time"
               [maxDate]="maxDateNow"
               ngbDatepicker
               #attachmentDate="ngbDatepicker"
               (click)="attachmentDate.toggle()" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="form-group col-6">
        <label>Script Type</label>
        <select
                jargonLoadingState="script-modal"
                type="text"
                class="form-control"
                formControlName="type">
          <option disabled [ngValue]="null">Select</option>
          <option value="single">Single</option>
          <option value="repeat">Repeat</option>
        </select>
      </div>

      <div class="form-group col-6">
        <label>Allow substitutes/generics</label>
        <div class="radio-group">
          <div class="custom-control custom-radio custom-control-inline">
            <input
                   type="radio"
                   id="substitutesYes"
                   name="substitutes"
                   formControlName="substitutes"
                   jargonLoadingState="script-modal"
                   class="custom-control-input mr-2"
                   value="Yes" />
            <label class="custom-control-label" for="substitutesYes">Yes</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
                   type="radio"
                   id="substitutesNo"
                   name="substitutes"
                   jargonLoadingState="script-modal"
                   formControlName="substitutes"
                   class="custom-control-input mr-2"
                   value="No" />
            <label class="custom-control-label" for="substitutesNo">No</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <label>Confirm Medications for Script</label>
      <table class="table table-bordered">
        <thead class="bg-light">
          <tr>
            <th>MEDICATION</th>
            <th>DOSAGE</th>
            <th>FREQUENCY</th>
            <th>DURATION</th>
            <th>DELIVERY</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let medication of medications">
            <td>{{ medication.nappi.Name }}</td>
            <td>{{ medication.quantity }} {{ medication.unit }}</td>
            <td>{{ medication.frequency }}</td>
            <td>{{ medication.period }}</td>
            <td>{{ medication.route }}</td>
            <td>
              <button
                      type="button"
                      jargonLoadingState="script-modal"
                      class="btn btn-sm btn-outline-primary me-2"
                      (click)="removeMedication(medication._id)">
                Remove from Script
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mb-3">
      <div class="form-group col-12">
        <label>Notes/Comments</label>
        <textarea jargonLoadingState="script-modal" type="text" class="form-control"
                  [formControl]="noteControl"></textarea>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button
            jargonLoadingState="script-modal"
            type="button"
            class="btn btn-outline-primary me-2"
            (click)="modalRef.close('cancel')">
      Cancel
    </button>
    <button
            jargonLoadingState="script-modal"
            type="button"
            class="btn btn-primary"
            (click)="submit()"
            [disabled]="!scriptForm.valid">
      Submit
    </button>
  </div>
</div>