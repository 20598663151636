import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AVAILABLE_SECTIONS, PRESETS as SECTION_PRESETS } from 'src/app/modules/ui/config/sections';
import { AVAILABLE_BUILDERS, PRESETS as BUILDER_PRESETS } from 'src/app/modules/ui/config/builders';
import { PRESETS as TAB_PRESETS } from 'src/app/modules/ui/config/tabs';
import { BuilderConfigItemModel, BuilderConfigModel, CustomBuilderConfigModel, CustomBuilderListModel, CustomBuilderMetaModel } from '../../models/builder-config.model';
import { BuilderData, ClinicalNoteModel, ReviewCommentDataModel, ReviewCommentModel } from '../../models/clincal-note.model';
import { EHRTabConfigModel } from '../../models/ehr-tab-config.model';
import { SectionConfigModel } from '../../models/section-config.model';
import { ActiveUserService } from './active-user.service';
import { ClinicalNoteHttpService } from '../http/clinical-note-http.service';
import { BehaviorSubject, catchError, firstValueFrom, forkJoin, Observable, of, switchMap, tap, throwError, zip } from 'rxjs';
import * as moment from 'moment';
import { map, take } from 'rxjs/operators';
import { DialogService } from './dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingsHttpService } from '../http/settings-http.service';
import { ClinicalNoteCommentHttpService } from '../http/clinical-note-comment-http.service';
import { ViewMode } from '../../types/util';
import { ViewModeEnum } from '../../types/enum/viewMode';
import { ToastService } from './toast.service';

export const CUSTOM_BUILDER_LIST_ALIAS = '_custom-builders';

type ConfigData = {
  readOnly: boolean;
  sections: SectionConfigModel[];
  builders: BuilderConfigModel[];
  tabs: EHRTabConfigModel[];
}

@Injectable({
  providedIn: 'root'
})
export class ClinicalNoteService {

  public config: BehaviorSubject<ConfigData> = new BehaviorSubject({
    readOnly: false,
    sections: [],
    builders: [],
    tabs: []
  } as ConfigData);
  private patientData?: any;
  private caseData?: any;
  private fhirData?: any;
  public viewMode?: ViewMode;
  public guid?: string;
  private sections: { [key: string]: BehaviorSubject<ClinicalNoteModel> } = {};
  private loadedData: { [key: string]: string } = {};

  public get patientId(): undefined | number {
    return this.patientData?.id;
  }

  public get fhirPatientId(): undefined | number {
    return this.fhirData?.id;
  }

  public get isExistingPatient(): boolean {
    return !!this.fhirData?.existing;
  }

  public get caseId(): undefined | number {
    return this.caseData?.id;
  }

  public get isReadOnly(): boolean {
    if (Object.values(this.sections).length === 0) {
      return this.viewMode !== ViewModeEnum.WRITE;
    }
    return Object.values(this.sections).some(v => v.value._submitted !== null && v.value._submitted !== undefined && !!v.value._submitted);
  }

  constructor(
    private router: Router,
    private activeUserService: ActiveUserService,
    private httpService: ClinicalNoteHttpService,
    private dialogService: DialogService,
    private settingsHttpService: SettingsHttpService,
    private commentHttpService: ClinicalNoteCommentHttpService,
    private toastService: ToastService
  ) {
  }

  public getCase() {
    // return copy of, not reference to
    return JSON.parse(JSON.stringify(this.caseData));
  }

  public getPatient() {
    // return copy of, not reference to
    return JSON.parse(JSON.stringify(this.patientData));
  }

  public section(alias: string): Observable<ClinicalNoteModel> {
    return this.sections[alias].asObservable();
  }

  public saveSection(alias: string, data: ClinicalNoteModel): Observable<boolean | null> {
    if (this.loadedData[alias]) {
      const isDataUnchanged = this.loadedData[alias] === JSON.stringify(data);
      if (isDataUnchanged) {
        return of(null);
      }
    }
    // return copy of, not reference to
    this.sections[alias].next(JSON.parse(JSON.stringify(data)));
    // Trigger a save here...
    return this.persist(alias).pipe(
      tap(res => this.loadedData[alias] = JSON.stringify(res)),
      map(() => true),
      catchError(() => {
        return of(false);
      })
    );
  }

  public saveBuilder(sectionAlias: string, builderAlias: string, data: BuilderData): Observable<BuilderData> {
    const now = moment().valueOf();
    // return copy of, not reference to
    this.sections[sectionAlias].getValue().builders = {
      ...this.sections[sectionAlias].getValue().builders || {},
      [builderAlias]: { _created: now, ...JSON.parse(JSON.stringify(data)), _modified: now }
    };
    return this.persist(sectionAlias).pipe(switchMap(() => of(this.sections[sectionAlias].getValue().builders[builderAlias])));
  }

  public deleteBuilder(sectionAlias: string, builderAlias: string,): Observable<boolean> {
    // remove builder from the section
    delete this.sections[sectionAlias].getValue().builders[builderAlias];
    // trigger a save here...
    return this.persist(sectionAlias).pipe(map(() => true));
  }

  public saveComment(sectionAlias: string, data: ReviewCommentDataModel): Observable<ReviewCommentModel> {

    let observable: Observable<ReviewCommentModel>;
    if (data._id) {
      observable = this.commentHttpService.update(this.patientId!, this.guid!, sectionAlias, data._id, data as ReviewCommentModel);
    } else {
      observable = this.commentHttpService.create(this.patientId!, this.guid!, sectionAlias, data);
    }

    // trigger a save here...
    return observable.pipe(
      switchMap((res) => this.loadSection(sectionAlias).pipe(map(() => res))),
    );
  }

  public deleteComment(sectionAlias: string, commentId: string): Observable<boolean> {
    return this.commentHttpService.delete(this.patientId!, this.guid!, sectionAlias, commentId).pipe(
      switchMap(() => this.loadSection(sectionAlias)),
      map(() => true)
    );
  }

  private persist(alias: string): Observable<ClinicalNoteModel> {
    let data = this.sections[alias].getValue();
    if (!data.notes) {
      data['notes'] = [];
    }
    const observable = data._created
      ? this.httpService.update(this.patientId!, this.guid!, alias, data)
      : this.httpService.create(this.patientId!, this.guid!, alias, data);

    return observable.pipe(
      take(1),
      tap(res => this.sections[alias].next(res)),
      catchError(err => {
        console.error(err);
        this.toastService.httpError(err, AVAILABLE_SECTIONS[alias].title + '\n');
        return throwError(() => err);
      })
    );
  }

  public submit() {
    const observable = this.httpService.submit(this.patientId!, this.guid!)
    return observable.pipe(
      take(1),
      switchMap(() => forkJoin(
        this.config.getValue().sections.map(({ alias }) => this.loadSection(alias).pipe(
          catchError(
            (err: HttpErrorResponse) => err.status === 404
              ? of({ builders: {} })
              : throwError(() => err)
          )
        ))
      )),
      tap(() => this.refreshSettings(false)),
      catchError(err => {
        this.dialogService.httpError(err);
        return throwError(() => err);
      })
    );
  }

  public async init() {
    return new Promise(async (resolve, reject) => {
      this.activeUserService.onInitialised(async (err) => {
        if (err) {
          console.error(err);
          reject(err);
          return;
        }

        const tokenData = this.activeUserService.parseMetaFromToken();

        const { mode, guid, patient: patientData, case: caseData, fhir: fhirData } = tokenData;
        this.patientData = patientData;
        this.caseData = caseData;
        this.fhirData = fhirData;
        this.viewMode = mode;
        this.guid = guid;

        if (!this.patientId || !this.caseId || !this.guid) {
          throw Error('Invalid Patient/Case Records');
        }

        const role = this.activeUserService.user!.role;

        const builderConfig = await this.setUpBuilders(role);

        this.config.next({
          readOnly: this.isReadOnly,
          sections: SECTION_PRESETS(role),
          builders: builderConfig,
          tabs: TAB_PRESETS(role)
        });

        console.log('[CONFIG]', JSON.stringify(this.config.getValue()));
        firstValueFrom(
          forkJoin(this.config.getValue().sections.map(
            ({ alias }) => this.loadSection(alias).pipe(
              tap(sectionData => {
                this.loadedData[alias] = JSON.stringify(sectionData);
              })
            )
          )).pipe(
            tap(() => this.refreshSettings(true)),
            catchError(err => {
              console.error(err);
              return of({
                builders: {}
              });
            })
          )
        )
          .then(resolve)
          .catch(reject);
      });
    }).catch(err => {
      this.router.navigate(['error']);
    });
  }

  public async setUpBuilders(role: string) {
    const builderConfig: BuilderConfigModel[] = BUILDER_PRESETS(role);
    let testingSection = builderConfig.find(v => v.alias === 'testing');
    let customSection = builderConfig.find(v => v.alias === 'custom');
    await Promise.all([
      !(customSection) ? null : firstValueFrom(
        forkJoin([
          this.settingsHttpService.find<CustomBuilderListModel>(this.activeUserService.id!, CUSTOM_BUILDER_LIST_ALIAS, undefined, 'user').pipe(
            map(v => v.data),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 404 || err.status === 401) {
                return of(null);
              }
              return throwError(() => err);
            })
          ),
          this.settingsHttpService.find<CustomBuilderListModel>(this.activeUserService.practiceId!, CUSTOM_BUILDER_LIST_ALIAS, undefined, 'practice').pipe(
            map(v => v.data),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 404 || err.status === 401) {
                return of(null);
              }
              return throwError(() => err);
            })
          )
        ]).pipe(
          tap(([userData, practiceData]) => {

            if (userData && userData.builders && userData.builders.length) {
              customSection!.builders = [
                ...customSection!.builders,
                ...userData.builders.map<BuilderConfigItemModel>(b => ({ ...AVAILABLE_BUILDERS['custom'], ...b, _scope: 'user' }))
              ];
            }

            if (practiceData && practiceData.builders && practiceData.builders.length) {
              customSection!.builders = [
                ...customSection!.builders,
                ...practiceData.builders.map<BuilderConfigItemModel>(b => ({ ...AVAILABLE_BUILDERS['custom'], ...b, _scope: 'practice' }))
              ];
            }
          }),
        )
      ),
      !(testingSection && testingSection.builders) ? null : firstValueFrom(
        this.settingsHttpService.find<string[]>(this.activeUserService.user!.id, 'testing_capabilities').pipe(
          tap(res => {
            if (res.data && res.data.length) {
              testingSection!.builders = testingSection!.builders.filter(
                c => c.group !== 'In-House Testing' || (res.data as Array<string>).includes(c.alias)
              );
            } else {
              testingSection!.builders = testingSection!.builders.filter(
                c => c.group !== 'In-House Testing'
              );
            }
          }),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              testingSection!.builders = testingSection!.builders.filter(
                c => c.group !== 'In-House Testing'
              );
              return of(null);
            }
            return throwError(() => err);
          }),
        )
      )
    ]);
    return builderConfig;
  }

  public async refreshSettings(reload: boolean) {
    const role = this.activeUserService.user!.role;
    const builderConfig = reload ? await this.setUpBuilders(role) : this.config.getValue().builders;
    this.config.next({
      readOnly: this.isReadOnly,
      sections: SECTION_PRESETS(role),
      builders: builderConfig,
      tabs: TAB_PRESETS(role)
    });
  }

  public loadSection(alias: string): Observable<ClinicalNoteModel> {
    return this.httpService.find(this.patientId!, this.guid!, alias).pipe(
      catchError(err => {
        if (err.status === 404) {
          return of({
            builders: {}
          });
        }
        return throwError(() => err);
      }),
      tap(data => {
        if (this.sections[alias]) {
          this.sections[alias].next(data);
        } else {
          this.sections[alias] = new BehaviorSubject<ClinicalNoteModel>(data)
        }
      })
    );
  }

  public customBuilderConfigAsMeta(config: CustomBuilderConfigModel): CustomBuilderMetaModel {
    return {
      title: config.title,
      label: config.label,
      alias: config.alias,
      group: config.group,
      author: config.author,
      scope: config.scope,
      active: config.active
    };
  }
}

export function ClinicalNoteServiceFactory(provider: ClinicalNoteService) {
  return () => provider.init();
}
