import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryViewComponent } from './components/history-view/history-view.component';
import { NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbPaginationModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { ComponentHostDirective } from './directives/component-host.directive';
import { AppDialogModal } from './services/common/dialog.service';
import { MedicalAndFamilyHistoryComponent } from './components/medical-and-family-history/medical-and-family-history.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TemplateRefDirective } from './directives/template-ref.directive';
import { HistoryDataPreviewComponent } from './components/history-data-preview/history-data-preview.component';
import { JargonTableComponent } from './components/jargon-table/jargon-table.component';
import { AttachmentsModalComponent } from './components/attachments-modal/attachments-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingStateDirective } from './directives/loading-state.directive';
import { DropzoneComponent } from './components/dropzone/dropzone.component';
import { MedpraxCodeTypeaheadComponent } from './components/medprax-code-typeahead/medprax-code-typeahead.component';
import { FilterCheckboxComponent } from './components/filter-checkbox/filter-checkbox.component';
import { ViewModeDirective } from './directives/view-mode.directive';
import { ViewModeFormDirective } from './directives/view-mode-form.directive';
import { ReviewActionsComponent } from './components/review-actions/review-actions.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PreferencesTypeaheadComponent } from './components/preferences-typeahead/preferences-typeahead.component';
import { MatSortModule } from '@angular/material/sort';
import { ScriptModalComponent } from './components/script-modal/script-modal.component';
import { ToastContainerComponent } from './components/toast-container/toast-container.component';


@NgModule({
  declarations: [
    HistoryViewComponent,
    ComponentHostDirective,
    AppDialogModal,
    MedicalAndFamilyHistoryComponent,
    PaginationComponent,
    TemplateRefDirective,
    HistoryDataPreviewComponent,
    TemplateRefDirective,
    JargonTableComponent,
    AttachmentsModalComponent,
    LoadingStateDirective,
    DropzoneComponent,
    MedpraxCodeTypeaheadComponent,
    ViewModeDirective,
    ViewModeFormDirective,
    ReviewActionsComponent,
    LoaderComponent,
    FilterCheckboxComponent,
    PreferencesTypeaheadComponent,
    ScriptModalComponent,
    ToastContainerComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    MatIconModule,
    NgbModalModule,
    NgbPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    MatSortModule,
    NgbTooltipModule,
    NgbToastModule
  ],
  exports: [
    ComponentHostDirective,
    TemplateRefDirective,
    HistoryViewComponent,
    MedicalAndFamilyHistoryComponent,
    PaginationComponent,
    JargonTableComponent,
    LoadingStateDirective,
    DropzoneComponent,
    MedpraxCodeTypeaheadComponent,
    ViewModeDirective,
    ViewModeFormDirective,
    ReviewActionsComponent,
    LoaderComponent,
    FilterCheckboxComponent,
    PreferencesTypeaheadComponent,
    HistoryDataPreviewComponent,
    ToastContainerComponent
  ]
})
export class SharedModule { }
