import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractBuilderComponent,
  BuilderDataAbstract,
  BuilderPresentNote
} from '../../shared/components/abstract-builder/abstract-builder.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryData } from '../../../../shared/components/history-view/history-view.component';
import { validateAllFormFields } from '../../../../shared/validators';
import { LoadingStateService } from '../../../../shared/services/common/loading-state.service';
import { BuilderData } from '../../../../shared/models/clincal-note.model';
import { map, merge } from 'rxjs';

interface CvsFormData extends BuilderDataAbstract {
  normal_s1_s2: BuilderPresentNote & { normal: boolean };
  jvp: BuilderPresentNote;
  hepar: BuilderPresentNote & { pulsations: boolean };
  p_oedema: BuilderPresentNote;
  basal_creps: BuilderPresentNote;
  peripheral_pulses: BuilderPresentNote;
  extra_sounds: BuilderPresentNote;
  abnormal_pulsations: BuilderPresentNote;
  calves_dvt: BuilderPresentNote;

  chest_wall_deformities?: BuilderPresentNote;
  apex_beats?: string;
  palpable_pulses?: BuilderPresentNote;
  parasternal_heave?: BuilderPresentNote;
  epigastric_pulsation?: BuilderPresentNote;
}

@Component({
  selector: 'app-cvs-exam',
  templateUrl: './cvs-exam.component.html',
  styleUrls: ['./cvs-exam.component.scss']
})
export class CvsExamComponent extends AbstractBuilderComponent {
  @ViewChild('preview') preview!: TemplateRef<unknown>;

  constructor(
    private fb: FormBuilder
  ) {
    super();
    this.formData = this.fb.group({
      normal_s1_s2: this.fb.group({
        present: [null],
        normal: [null],
        note: [null, Validators.maxLength(500)]
      }),
      jvp: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      hepar: this.fb.group({
        present: [null],
        pulsations: [null],
        note: [null, Validators.maxLength(500)]
      }),
      p_oedema: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      basal_creps: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      peripheral_pulses: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      extra_sounds: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      abnormal_pulsations: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      calves_dvt: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),

      chest_wall_deformities: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      apex_beats: [null, Validators.maxLength(500)],
      palpable_pulses: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      parasternal_heave: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      }),
      epigastric_pulsation: this.fb.group({
        present: [null],
        note: [null, Validators.maxLength(500)]
      })
    });

    //toggle form validation

    if (!this.isReadOnly) {
      if (this.controlSub) this.controlSub.unsubscribe();
      this.controlSub = merge(
        ...Object.values(this.formData.controls)
          .filter(c => !!c.get('present'))
          .map(c => c.valueChanges.pipe(map(_ => c as FormGroup)))
      ).subscribe({
        next: v => {
          switch (v.get('present')?.value) {
            case true:
              v.get('note')?.enable({ emitEvent: false });
              v.get('normal')?.enable({ emitEvent: false });
              v.get('pulsations')?.enable({ emitEvent: false });
              break;
            case false:
              v.get('note')?.enable({ emitEvent: false });
              v.get('normal')?.disable({ emitEvent: false });
              v.get('normal')?.patchValue(null, { emitEvent: false });
              v.get('pulsations')?.disable({ emitEvent: false });
              v.get('pulsations')?.patchValue(null, { emitEvent: false });
              break;
            case null:
              v.get('note')?.disable({ emitEvent: false });
              v.get('note')?.patchValue('', { emitEvent: false });
              v.get('normal')?.disable({ emitEvent: false });
              v.get('normal')?.patchValue(null, { emitEvent: false });
              v.get('pulsations')?.disable({ emitEvent: false });
              v.get('pulsations')?.patchValue(null, { emitEvent: false });
              break;
          }
        }
      });
    }

  }

  public override async validate() {
    validateAllFormFields(this.formData);

    return this.formData.valid;
  }

  serialize(data: CvsFormData): HistoryData[] {
    if (!data) {
      return [];
    }

    const list = this.prepareSerializedList(data);

    return [{
      type: 'builder',
      builderTitle: 'CVS EXAM:',
      author: data.author ? data.author.name : '',
      dateTime: moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z'),
      template: [this.preview, list]
    }];
  }

  asString(data: CvsFormData): string {
    if (!data) return '';

    const list = this.prepareSerializedList(data);
    return [
      `[${moment(data._modified!).format('YYYY-MM-DD HH:mm:ss Z')}${data.author ? `; ${data.author.name}` : ''}]: CVS EXAM`,
      ...list.filter(v => v.value !== null).map(
        v => `${v.title}: ${v.present === undefined ? '' : (v.present ? 'Present' : 'Not Present')}${v.value}`
      )
    ].join('\n');
  }

  protected getBuilderData(): BuilderData {
    const data = this.formData.getRawValue() as CvsFormData;
    data.normal_s1_s2['_modified'] = moment().valueOf();
    data.jvp['_modified'] = moment().valueOf();
    data.hepar['_modified'] = moment().valueOf();
    data.p_oedema['_modified'] = moment().valueOf();
    data.basal_creps['_modified'] = moment().valueOf();
    data.peripheral_pulses['_modified'] = moment().valueOf();
    data.extra_sounds['_modified'] = moment().valueOf();
    data.abnormal_pulsations['_modified'] = moment().valueOf();
    data.calves_dvt['_modified'] = moment().valueOf();
    if (data.chest_wall_deformities) data.chest_wall_deformities['_modified'] = moment().valueOf();
    if (data.palpable_pulses) data.palpable_pulses['_modified'] = moment().valueOf();
    if (data.parasternal_heave) data.parasternal_heave['_modified'] = moment().valueOf();
    if (data.epigastric_pulsation) data.epigastric_pulsation['_modified'] = moment().valueOf();

    let builderData = this.data;
    if (!builderData) {
      builderData = data;
    } else {
      builderData['normal_s1_s2'] = data.normal_s1_s2;
      builderData['jvp'] = data.jvp;
      builderData['hepar'] = data.hepar;
      builderData['p_oedema'] = data.p_oedema;
      builderData['basal_creps'] = data.basal_creps;
      builderData['peripheral_pulses'] = data.peripheral_pulses;
      builderData['extra_sounds'] = data.extra_sounds;
      builderData['abnormal_pulsations'] = data.abnormal_pulsations;
      builderData['calves_dvt'] = data.calves_dvt;
      builderData['chest_wall_deformities'] = data.chest_wall_deformities;
      builderData['apex_beats'] = data.apex_beats;
      builderData['palpable_pulses'] = data.palpable_pulses;
      builderData['parasternal_heave'] = data.parasternal_heave;
      builderData['epigastric_pulsation'] = data.epigastric_pulsation;
    }
    return builderData;
  }


  private prepareSerializedList(data: CvsFormData) {
    return [
      {
        title: 'Normal S1 S2:',
        present: data.normal_s1_s2.present,
        value: `${data.normal_s1_s2.normal === null ? '' : (data.normal_s1_s2.normal ? ' [S1 S2 normal' : ' [S1 S2 abnormal')}${data.normal_s1_s2.note ? data.normal_s1_s2.normal !== null ? ', ' + data.normal_s1_s2.note + ']' : ' [' + data.normal_s1_s2.note + ']' : data.normal_s1_s2.normal !== null ? ']' : ''}`
      },
      {
        title: 'JVP:',
        present: data.jvp.present,
        value: `${data.jvp.note ? ' [' + data.jvp.note + ']' : ''}`
      },
      {
        title: 'Hepar:',
        present: data.hepar.present,
        value: `${data.hepar.pulsations !== null ? ' [Hepatic Pulsations - ' + (data.hepar.pulsations ? 'Yes' : 'No') : ''}${data.hepar.note ? data.hepar.pulsations !== null ? '; ' + data.hepar.note + ']' : data.hepar.note + ']' : data.hepar.pulsations !== null ? ']' : ''}`
      },
      {
        title: 'P Oedema:',
        present: data.p_oedema.present,
        value: `${data.p_oedema.note ? ' [' + data.p_oedema.note + ']' : ''}`
      },
      {
        title: 'Basal Creps:',
        present: data.basal_creps.present,
        value: `${data.basal_creps.note ? ' [' + data.basal_creps.note + ']' : ''}`
      },
      {
        title: 'Peripheral Pulses:',
        present: data.peripheral_pulses.present,
        value: `${data.peripheral_pulses.note ? ' [' + data.peripheral_pulses.note + ']' : ''}`
      },
      {
        title: 'Extra Sounds:',
        present: data.extra_sounds.present,
        value: `${data.extra_sounds.note ? ' [' + data.extra_sounds.note + ']' : ''}`
      },
      {
        title: 'Abnormal Pulsation:',
        present: data.abnormal_pulsations.present,
        value: `${data.abnormal_pulsations.note ? ' [' + data.abnormal_pulsations.note + ']' : ''}`
      },
      {
        title: 'Calves/DVT:',
        present: data.calves_dvt.present,
        value: `${data.calves_dvt.note ? ' [' + data.calves_dvt.note + ']' : ''}`
      },
      {
        title: 'Chest Wall Deformities:',
        present: data.chest_wall_deformities ? data.chest_wall_deformities?.present : null,
        value: `${data.chest_wall_deformities?.note ? ' [' + data.chest_wall_deformities.note + ']' : ''}`
      },
      ...(data.apex_beats && data.apex_beats !== '' ? [{
        title: 'Apex Beats:',
        value: data.apex_beats
      }] : []),
      {
        title: 'PP2:',
        present: data.palpable_pulses ? data.palpable_pulses?.present : null,
        value: `${data.palpable_pulses?.note ? ' [' + data.palpable_pulses.note + ']' : ''}`
      },
      {
        title: 'PSH:',
        present: data.parasternal_heave ? data.parasternal_heave?.present : null,
        value: `${data.parasternal_heave?.note ? ' [' + data.parasternal_heave.note + ']' : ''}`
      },
      {
        title: 'Epigastric Pulsation:',
        present: data.epigastric_pulsation ? data.epigastric_pulsation?.present : null,
        value: `${data.epigastric_pulsation?.note ? ' [' + data.epigastric_pulsation.note + ']' : ''}`
      },
    ].filter(v => v.present !== null);
  }
}
